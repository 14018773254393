import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import RebootTable from 'components/Web_User_Interface/720p_Series/Software/Reboot_Reset/rebootTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Software Menu // Reboot & Reset",
  "path": "/Web_User_Interface/720p_Series/Software/Reboot_Reset/",
  "dateChanged": "2017-12-10",
  "author": "Mike Polinowski",
  "excerpt": "Use this function to reboot or reset your camera safely. It is not recommended to simply unplug/replug your camera or use the hardware reset unnecessarily (those actions can be harmful to running operations as well as causing hardware defects on the internal SD card). Please always use the soft- restart and reset via the web user interface.",
  "image": "./WebUI_720p_SearchThumb_Software_Reboot_Reset.png",
  "social": "/images/Search/WebUI_720p_SearchThumb_Software_Reboot_Reset.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "720p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Software Menu // Reboot & Reset' dateChanged='2017-12-10' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use this function to reboot or reset your camera safely. It is not recommended to simply unplug/replug your camera or use the hardware reset unnecessarily (those actions can be harmful to running operations as well as causing hardware defects on the internal SD card). Please always use the soft- restart and reset via the web user interface.' image='/images/Search/WebUI_720p_SearchThumb_Software_Reboot_Reset.png' twitter='/images/Search/WebUI_720p_SearchThumb_Software_Reboot_Reset.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/720p_Serie/Software/Neustart_Werkseinstellungen/' locationFR='/fr/Web_User_Interface/720p_Series/Software/Reboot_Reset/' crumbLabel="Reboot & Reset" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "720p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#720p-web-user-interface",
        "aria-label": "720p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`720p Web User Interface`}</h1>
    <h2 {...{
      "id": "software-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#software-menu",
        "aria-label": "software menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software Menu`}</h2>
    <h3 {...{
      "id": "reboot--reset",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#reboot--reset",
        "aria-label": "reboot  reset permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reboot & Reset`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <blockquote>
      <p parentName="blockquote">{`Change your camera´s IP configuration or WiFi settings or connect it to your smartphone by scanning the DDNS, P2P or Push Service QR code with our smartphone app.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "716px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/557988ffbbcd5edbfed15b6d6bebc5fc/6bbf7/WebUI-Software_Reboot.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAIAAADJt1n/AAAACXBIWXMAAAsTAAALEwEAmpwYAAAC8UlEQVQ4y4WTa0/aUBjH+Vbbvscur/0wi9sbE2MCzLZSWNQYZSCS6tbCaLnEKtRNHQr0nNN7C73iJfDOpS1jGF32e3ea/Po8//OcJ1Eul1+/efvi5au3797hOIZhGElmMQxbX/+E4zhJkgRBZMhMKhlCbBC53GeSJNPpdIbMJCiKSiaTHz58TCZTR0dHHMexLPs9orZAeK6GX1iWrdfrpVJpaWkpkcvlyExmd3d3f38fIaTr2q9uNwiCm5ub4DHjIAj8Gbe3t2trawme52maEYQzQRBEEViW1etd9/t9VVWNf6Dr+mQyaTQaiUPqcPn9cjqdPjg4MAxT13X++PiY53v9viRJ6AkAAAjhdDplGCYx6A867Y5hGANRVBTFsqzz8/PLy8t+v6dpmvoERVFUVZ1Op5VKJUHT3yiKarVaLMsBAIbD4ZkgdLtdCKH0HAihuHIoZzYyKysre3u7xWJRFEXLMjudzsXFBULoX5UVRZnJm5ubBEFsb2/n818AgKPR6Or6WlaUZwMvZg7lra0tkiTzEaIo+r6vyLKmabphjEYj5wm2bZumOZlMQjmXzRIEXi6XC4XCYDDwfS+WNU3zH+NFuK5rWdZMzufzWxGFQhEA4Ps+QkiJ2oYQOq4bO7Hs+4HnefGcQ1kQhGq12ogAAHqeJ0mSLIczs23b+8NoODQNU5FlXdNlWZ5lrtVqXyMYpoKQ5LouguHF2LY9b9X3fdM0dd0AAMRNzeR2u03TdPTcGwCA+EmPx+O5+bhtP449a7vVbNI0Xfse7g2EcDweO47j/on6LI7j/M3McRzP881mM668eLf/kVutVqVaYSMQQp7naZpmmua8uBuxKA+Hw7+V2yenZ8JZvV4HADiOI0Wjipt3XTcIgvkvYgzDuL+/D7dKEITTk9MfP37WajXLsu7u7kRRjIcMIQQAdDqdq6ureB8ghKIoqqr68PAQ7jPHcQzDVKohpVJpZ2cHx/GNBVKpFIZh8yNBENlslqKo1dXV32cQEtDrpMwvAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/557988ffbbcd5edbfed15b6d6bebc5fc/e4706/WebUI-Software_Reboot.avif 230w", "/en/static/557988ffbbcd5edbfed15b6d6bebc5fc/d1af7/WebUI-Software_Reboot.avif 460w", "/en/static/557988ffbbcd5edbfed15b6d6bebc5fc/04ac8/WebUI-Software_Reboot.avif 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/557988ffbbcd5edbfed15b6d6bebc5fc/a0b58/WebUI-Software_Reboot.webp 230w", "/en/static/557988ffbbcd5edbfed15b6d6bebc5fc/bc10c/WebUI-Software_Reboot.webp 460w", "/en/static/557988ffbbcd5edbfed15b6d6bebc5fc/d8378/WebUI-Software_Reboot.webp 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/557988ffbbcd5edbfed15b6d6bebc5fc/81c8e/WebUI-Software_Reboot.png 230w", "/en/static/557988ffbbcd5edbfed15b6d6bebc5fc/08a84/WebUI-Software_Reboot.png 460w", "/en/static/557988ffbbcd5edbfed15b6d6bebc5fc/6bbf7/WebUI-Software_Reboot.png 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/557988ffbbcd5edbfed15b6d6bebc5fc/6bbf7/WebUI-Software_Reboot.png",
              "alt": "Web User Interface - 720p Series - Software Reboot & Reset",
              "title": "Web User Interface - 720p Series - Software Reboot & Reset",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can download regular software updates for your camera from `}<a href="https://www.instar.com/support/downloads/" target="_blank" rel="noopener noreferrer">{`download.instar.de`}</a>{`. Please use the browse button to set the file path once you downloaded and unzipped the update BIN and click Submit to upload the file to your camera. Use the update function only when the camera is connected to the router by LAN cable!`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <RebootTable mdxType="RebootTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      